import axios from "axios";
import {QuestionSearchResult } from "@/types/question_search_result"
export type GetListParams = {
  start_date: string;
  end_date: string;
  question_category_id?: number;
}
export class QuestionSearchResultRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = QuestionSearchResultRepository.DOMAIN + "v1/projects/" + projectId +"/question_search_results/"
  }


  async getList(params: GetListParams): Promise<QuestionSearchResult[]> {
    return axios.get(this.url, { params: params }).then((response) => {
      return response.data.search_results;
    });
  }
}
