import axios from "axios";
import { QuestionCategory } from '@/types/question_category'

export class QuestionCategoryRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  constructor(private projectId: number) { }

  async getList(): Promise<QuestionCategory[]> {
    return axios.get(QuestionCategoryRepository.DOMAIN + "v1/question_categories/", { params: { project_id: this.projectId } }).then((response) => {
      return response.data.question_categories;
    });
  }
  async getTreeList(): Promise<{id: number, name: string}[]> {
    return this.getList().then((categories) => {
      const results: {id: number, name: string}[] = [];

      const process = (categories: QuestionCategory[], depth: number = 0) => {
        categories.forEach((category) => {
          results.push({id: category.id, name: '　'.repeat(depth) + category.name})

          if (category.children && category.children.length > 0) {
            process(category.children, depth + 1);
          }
        });
        return results;
      };
      process(categories);

      return results;
    });
  }
  async update(targetId: number, name: string): Promise<QuestionCategory[]> {
    return axios.put(QuestionCategoryRepository.DOMAIN + "v1/question_categories/" + targetId, { project_id: this.projectId, question_category: { name: name } }).then((response) => {
      return response.data.question_categories;
    })
  }
  async add(parentId: number, name: string,): Promise<QuestionCategory[]> {
    return axios.post(QuestionCategoryRepository.DOMAIN + "v1/question_categories", { project_id: this.projectId, question_category: { parent_id: parentId, name: name } }).then((response) => {
      return response.data.question_categories

    })
  }
  async delete(targetId: number): Promise<QuestionCategory[]> {
    return axios.delete(QuestionCategoryRepository.DOMAIN + "v1/question_categories/" + targetId, { params: { project_id: this.projectId } }).then((response) => {
      return response.data.question_categories
    })
  }
  async sort(targetId: number, dependId: number, type: string): Promise<QuestionCategory[]> {
    return axios.post(QuestionCategoryRepository.DOMAIN + "v1/question_categories/" + targetId + "/sort", { project_id: this.projectId, depend_id: dependId, type: type }).then((response) => {
      return response.data.question_categories

    })
  }
}
