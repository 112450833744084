import { ref, watch } from "vue";
import { ElMessage } from "element-plus"
import axios from 'axios';
import { useRoute } from 'vue-router'
import { QuestionSearchResult } from "@/types/question_search_result"
import { QuestionSearchResultRepository, GetListParams } from '@/repositories/QuestionSearchResultRepository'
import { QuestionCategoryRepository } from '@/repositories/QuestionCategoryRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic';
export const QuestionSearchResultLogic = () => {

    const questionSearchResults = ref<QuestionSearchResult[]>([])
    const questioSearchResultErrors = ref("");
    const questionSearchResultLoading = ref(false)

    const categoryList = ref<{id: number, name: string}[]>([]);

    const route = useRoute();

    const getList = async (params?: GetListParams) => {
        if (params == undefined) {
            params = {start_date: "", end_date: ""}
        }
        questionSearchResultLoading.value = true
        const projectId = Number(route.params.project_id);
        const repository: QuestionSearchResultRepository = new QuestionSearchResultRepository(projectId);
        try {
            questionSearchResults.value = await repository.getList(params);
            questionSearchResultLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                questioSearchResultErrors.value = `検索履歴の読み込みに失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }
    };
    const getCategoryList = async () => {
      const projectId = Number(route.params.project_id);
      const repository = new QuestionCategoryRepository(projectId);

      try {
        categoryList.value = await repository.getTreeList();
      } catch (e: any) {
        useExceptionMessage(e);
      }
    };
    watch(questioSearchResultErrors, () => {
        if (questioSearchResultErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: questioSearchResultErrors.value,
            type: 'error'
        });
        questioSearchResultErrors.value = "";
    })
    return {
        questionSearchResults,
        getList,
        questionSearchResultLoading,
        questioSearchResultErrors,
        getCategoryList,
        categoryList,
    }
}


